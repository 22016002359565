import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Config from '../../../helpers/Config';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import { Media, Row, Col, Container } from "reactstrap";
import aboutImg from '../../../resources/custom/images/about-us.jpg';
import CustomerTestimonial from '../../components/shared/CustomerTestimonial';

const Privacy = () => {
  const [siteTitle, setSiteTitle] = useState(Config['SITE_TTILE']);
  return (
    <>


      <Helmet>
        <title>{siteTitle} - About Us</title>
        <meta name="description" content={siteTitle + " - About us page"}  />
        <meta name="keywords" content="About us"></meta>
      </Helmet>

      <SiteBreadcrumb title="About-Us" parent="Home"/>

      <section className="about-page section-big-py-space">
        <div className="custom-container">
          <Row>
          <div class="container mt-5">
    <h1 class="text-center mb-4">Privacy Policy</h1>

    <section class="mb-4">
      <h2>Introduction</h2>
      <p>Welcome to PROTEINKING. Your privacy is important to us, and we are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your information when you visit our website.</p>
    </section>

    <section class="mb-4">
      <h2>Information We Collect</h2>
      <ul>
        <li><strong>Personal Information:</strong> When you create an account, make a purchase, or contact us, we may collect personal data such as your name, email address, shipping address, and payment details.</li>
        <li><strong>Non-Personal Information:</strong> We may collect non-personal data such as your browser type, device information, and how you interact with our website to improve user experience.</li>
      </ul>
    </section>

    <section class="mb-4">
      <h2>How We Use Your Information</h2>
      <ul>
        <li><strong>To Process Transactions:</strong> We use your personal information to process and fulfill your orders and to communicate with you about your purchases.</li>
        <li><strong>To Improve Our Services:</strong> We analyze non-personal information to enhance our website and services.</li>
        <li><strong>To Provide Customer Support:</strong> Your information helps us respond to your requests and support needs.</li>
        <li><strong>For Marketing Purposes:</strong> With your consent, we may send promotional materials that we think may be of interest to you.</li>
      </ul>
    </section>

    <section class="mb-4">
      <h2>How We Protect Your Information</h2>
      <p>We implement security measures to maintain the safety of your personal data. However, no method of transmission over the internet or method of storage is 100% secure.</p>
    </section>

    <section class="mb-4">
      <h2>Sharing Your Information</h2>
      <p>We do not sell, trade, or otherwise transfer your personal information to third parties except as necessary to provide our services or comply with the law.</p>
    </section>

    <section class="mb-4">
      <h2>Third-Party Services</h2>
      <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices of such sites.</p>
    </section>

    <section class="mb-4">
      <h2>Your Consent</h2>
      <p>By using our site, you consent to our privacy policy.</p>
    </section>

    <section class="mb-4">
      <h2>Changes to Our Privacy Policy</h2>
      <p>We may update this policy occasionally. We will notify you of significant changes through our website or contact you directly.</p>
    </section>

    {/* <section>
      <h2>Contact Us</h2>
      <p>If you have any questions or concerns regarding this Privacy Policy, please contact us at [Your Contact Information].</p>
    </section> */}
  </div>
          </Row>
        </div>
      </section>
    </>
  );

}

export default Privacy;
