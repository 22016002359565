import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Config from '../../../helpers/Config';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import { Media, Row, Col, Container } from "reactstrap";
import aboutImg from '../../../resources/custom/images/about-us.jpg';
import CustomerTestimonial from '../../components/shared/CustomerTestimonial';

const About = () => {
  const [siteTitle, setSiteTitle] = useState(Config['SITE_TTILE']);

  return (
    <>


      <Helmet>
        <title>{siteTitle} - About Us</title>
        <meta name="description" content={siteTitle + " - About us page"}  />
        <meta name="keywords" content="About us"></meta>
      </Helmet>

      <SiteBreadcrumb title="About-Us" parent="Home"/>

      <section className="about-page section-big-py-space">
        <div className="custom-container">
          <Row>
            <Col lg="12">
              <h4>About our Store</h4>
              <p className="mb-2">
                {" "}
                Welcome to PROTEINKING , where our passion for fitness and nutrition drives everything we do. Founded in 2016, we are dedicated to empowering individuals on their fitness journeys through high-quality protein products designed to support a variety of goals.
              </p>
              <p className="mb-2">
                {" "}
                Our team comprises fitness enthusiasts and nutrition experts committed to excellence. We understand that every body is unique, so we offer a diverse range of protein options tailored to meet different dietary needs and preferences. Quality is our top priority; we source only the finest ingredients and maintain strict safety standards.
              </p>
              <p className="mb-2">
                {" "}
                More than just a brand, we are a community. We actively engage with local gyms and fitness events, supporting initiatives that promote health and well-being. Our goal is to provide not only exceptional products but also valuable resources to help you make informed nutrition choices.
              </p>
              <p>
                {" "}
                Quality and transparency are at the core of our values. We meticulously source our ingredients and maintain rigorous testing to guarantee safety and efficacy in every product.
              </p>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );

}

export default About;
